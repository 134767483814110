import axios from '@/libs/api.request'
import {backToHomePage,globaluserId} from "../libs/rongxunUtil"
// import router from "../router/router"

export default function (url, data, header = {}) {
  var dataLog = {}
  dataLog.systemUrl = url
  dataLog.user_id = globaluserId()
  dataLog.data = data

  return axios.request({ // 这里返回的是一个Promise，request方法传入一个配置对象，配置项可参考axios
    url: url,
    method: 'post',
    data: data,
    headers: header
  }).then(function (response) {
    if(response.data.code == "-8")  backToHomePage()

    var p = new Promise(function(resolve){
      resolve (response)
    })

    return p
  })

}
