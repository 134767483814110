import CryptoJS from 'crypto-js'
import { encodeFlag } from '../config/env'

//
//
export const encryption = function (data) {
  if (encodeFlag) {
    var json = JSON.stringify(data)
    var key = getKey()
    var result = encryptByDES(json, key)
    var new_data = {}
    new_data['a'] = result
    return new_data
  }

  return data
}

var encryptByDES = function (message, key) {
  const keyHex = CryptoJS.enc.Utf8.parse(key)
  const encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}

var getKey = function () {
  return 'domed123'
}
